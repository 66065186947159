import { adminApi, CacheTags } from 'api/adminApi';
import { CustomerMutation } from 'models';

const customerApi = adminApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get customers
     */
    getCustomers: build.query<CustomerMutation[], void>({
      query: () => 'customer',
      providesTags: [CacheTags.Customer],
      transformResponse: (result: CustomerMutation[]) => {
        return result.sort((a, b) =>
          (a.name ?? '') > (b.name ?? '') ? 1 : -1
        );
      },
    }),

    /**
     * Update customer
     */
    updateCustomer: build.mutation({
      query: (customer: CustomerMutation) => ({
        url: `customer/${customer.id}`,
        method: 'POST',
        body: customer,
      }),
      invalidatesTags: [CacheTags.Customer],
      transformResponse: (result: CustomerMutation, _meta, arg) => {
        return {
          ...arg,
          ...result,
        };
      },
    }),
  }),
});

export const { useGetCustomersQuery, useUpdateCustomerMutation } = customerApi;
